// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("./login")

require("./multi-step-form")
require("./project/service_requested")
require("./custom-radio-button")
require("./clickable-row")
require("./accept_project")
require("./uploads")
require("./clone_form")
require("jquery")
require("./debounce")
require("@nathanvda/cocoon")
require("./sort-table")
require("./project_folders")
require("./lead_stages")
require("./filter")
require("./format-search-team-result")
require("./plan_review")
require("./permit_fee_share")
require("./richtext")
require("./copy_link_to_page")
require("./signature")
require("./user_permissions")
require("./user_role_permissions")

// inspections
require("./inspections/note")
require("./inspections/deletable-row")
require("./inspections/invite-contractor-countdown")

// contractor portal
require("./contractor_portal/account_confirmation_form")
require("./contractor_portal/user_onboarding_form")
require("./contractor_portal/new_permit_form/selection")
require("./contractor_portal/new_permit_form/multi-step-form")
require("./contractor_portal/new_permit_form/permit-information")
require("./contractor_portal/new_permit_form/permit-summary")
require("./contractor_portal/new_permit_form/payment")
require("./contractor_portal/permit_fee_structure_form")

// hud trip report
require("./hud_trip_report/multi-step-form")

import "chartkick/chart.js"


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
require('livekit-client')
require('html2canvas')

// Calendar for INSPECTIONS
import "flatpickr";
import "flatpickr/dist/flatpickr.min.css";