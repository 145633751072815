document.addEventListener('turbolinks:load', function () {
    if ($('.teams.show, .invitations.new, .invitations.create').length === 0) {
        return false
    }

    $('#user_kind_admin').on('change', function () {
        $('#user_custom_role').addClass('d-none').attr('required', false);
        if ($(this).prop('checked') === true) {
            $(".permission-wrapper input[type='checkbox']").prop('checked', true);
        }
    });

    $('#user_kind_plans_examiner').on('change', function () {
        $(".permission-wrapper input[type='checkbox']").prop('checked', false);
      $('#user_custom_role').addClass('d-none').attr('required', false);
        if ($(this).prop('checked') === true) {
            [
              'type_projects_view',
              'type_projects_create',
              'type_projects_update',
              'type_projects_change_project_type',
              'type_projects_change_project_status',
              'type_projects_complete_final_inspection',
              'type_projects_start_plan_review',
              'type_projects_upload_design_documents',
              'type_teams_view',
              'type_contractors_view',
              'type_contractors_create',
              'type_contractors_update',
              'type_contractors_delete',
              'type_contractors_change_contractor_status',
              'type_inspections_view',
            ].forEach (function (value) {
                $('#' + value).prop('checked', true);
            });
        }
    });

    $('#user_kind_inspector').on('change', function () {
        $(".permission-wrapper input[type='checkbox']").prop('checked', false);
      $('#user_custom_role').addClass('d-none').attr('required', false);;
        if ($(this).prop('checked') === true) {
            [
              'type_projects_view',
              'type_projects_create',
              'type_projects_update',
              'type_projects_change_project_type',
              'type_projects_change_project_status',
              'type_projects_complete_final_inspection',
              'type_projects_start_plan_review',
              'type_projects_upload_design_documents',
              'type_teams_view',
              'type_contractors_view',
              'type_contractors_create',
              'type_contractors_update',
              'type_contractors_delete',
              'type_contractors_change_contractor_status',
              'type_inspections_view',
              'type_inspections_export_inspection_details',
              'type_inspections_update',
              'type_inspections_change_to_onsite',
              'type_inspections_change_to_virtual',
              'type_inspections_change_inspector',
              'type_inspections_cancel',
              'type_inspections_reschedule',
              'type_inspections_add_files',
              'type_inspections_destroy_files',
              'type_inspections_update_status'
            ].forEach (function (value) {
                $('#' + value).prop('checked', true);
            });
        }
    });

    $('#user_kind_permit_clerk').on('change', function () {
        $(".permission-wrapper input[type='checkbox']").prop('checked', false);
        $('#user_custom_role').addClass('d-none').attr('required', false);
        if ($(this).prop('checked') === true) {
            [
                "type_projects_view",
                "type_projects_create",
                "type_projects_update",
                "type_projects_change_project_type",
                "type_projects_change_project_status",
                "type_projects_complete_final_inspection",
                "type_projects_export_project_details",
                "type_projects_assign_project",
                "type_teams_view",
                "type_teams_create",
                "type_teams_update",
                "type_teams_change_roles_and_permission",
                "type_contractors_view",
                "type_contractors_create",
                "type_contractors_update",
                "type_contractors_change_contractor_status",
                "type_inspections_view",
                "type_inspections_export_inspection_details",
                "type_inspections_update",
                "type_inspections_change_to_onsite",
                "type_inspections_change_to_virtual",
                "type_inspections_change_inspector",
                "type_inspections_cancel",
                "type_inspections_reschedule",
                "type_inspections_add_files",
                "type_inspections_update_status",
            ].forEach (function (value) {
                $('#' + value).prop('checked', true);
            });
        }
    });

    $('#user_kind_other').on('change', function () {
        $(".permission-wrapper input[type='checkbox']").prop('checked', false);
        $('#user_custom_role').removeClass('d-none').attr('required', true);;
    })
});